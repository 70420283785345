@import "tailwind.css";
@import "tippy.js/dist/tippy.css";

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: 2px solid #4a90e2;
  box-shadow: 50 50 5px rgba(74, 144, 226, 0.5);
  transition: box-shadow 0.2s ease-in-out, outline 0.2s ease-in-out;
  outline-offset: 2px;
}

.focused:focus-within {
  outline: 2px solid #4a90e2;
  box-shadow: 50 50 5px rgba(74, 144, 226, 0.5);
  transition: box-shadow 0.2s ease-in-out, outline 0.2s ease-in-out;
  outline-offset: 2px;
}

.checkbox:focus {
  outline: none;
  border: 2px solid #4a90e2;
}

.form-input:focus {
  border-color: #4a90e2;
  box-shadow: 0 0 5px rgba(74, 144, 226, 0.5);
}

.has-error .form-input {
  border-color: #e3342f;
}

.has-success .form-input {
  border-color: #1abc9c;
}

.radio-container:focus-within {
  border: 2px solid #4361eec2;
  border-radius: 5px;
}

.table-container {
  position: relative;
  overflow: visible;
}

.dropdown ul {
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  z-index: 20;
}

.dropdown ul li a {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  white-space: nowrap;
}

.red-menu a {
  color: #000;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.red-menu a:hover {
  background-color: rgba(255, 0, 0, 0.05) !important;
  color: #ff0000 !important;
}

.content-area {
  margin-top: 4rem;
}

input[type="checkbox"]:focus {
  box-shadow: 0 0 10px 2px rgba(0, 123, 255, 0.5);
  border: 2px solid blue !important;
  border-radius: 4px;
}

.indicator-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: red;
  position: absolute;
  left: 2px;
  margin-bottom: 2px;
}

::ng-deep .custom-tooltip {
  background-color: #ffc107;
  color: #000;
  border-radius: 4px;
  padding: 8px;
  font-size: 14px;
}

.alert-with-icon {
  display: flex;
  align-items: center;
  gap: 5px;
}

.country-add .ng-select.ng-select-single .ng-select-container {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;
}

.country-add .autoselect-remote {
  width: 100%;
}

.custom-large-modal .modal-content {
  overflow-y: auto;
}

.custom-modal .modal-dialog {
  max-width: 35%;
}

@media (max-width: 768px) {
  .custom-modal .modal-dialog {
    max-width: 95%;
    width: 95%;
  }
}

.custom-modal .modal-content {
  overflow-y: auto;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.icon-black {
  fill: black !important;
}

.tab-active {
  border-bottom: 2px solid black;
}

.disabled-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(200, 200, 200, 0.5);
  /* Gray semi-transparent */
  top: 0;
  left: 0;
  z-index: 10;
  border-radius: 4px;
}
